<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters"> 
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="team_ids"
                  :not-list="false"
                  :options="teamList"
                  :title="$t('scope.Team') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="170px"
                  :isOpenMultiselect="true"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="order_ids"
                  :not-list="false"
                  :options="orderList"
                  :title="$t('report.order_no') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="170px"
                  name="order_ids"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="company_ids"
                  :not-list="false"
                  :options="companyList"
                  :title="$t('order.customer') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="170px"
                  name="order_company_id"
                ></custom-multi-select>
              </div> 
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="supplier_ids"
                  :not-list="false"
                  :options="supplierList"
                  :title="$t('Tedarikçi') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="170px"
                  name="supplier_id"
                ></custom-multi-select>
              </div> 
            </div>
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-4 col-md-6 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row"> 
        <div class="col-12">
          <dashboard-box :title="$t('Tedarikçi Başarı Bar Grafiği')">
            <template v-slot:preview>
              <div>
                <div class="graphic-container">
                  <apexchart
                  ref="lineChart"
                    type="bar"
                    height="500" 
                    :options="chartOptions"
                    :series="series" 
                    style="padding: 20px" 
                  ></apexchart>
                </div>
                <div class="row m-0 p-0 ml-5 mb-4 mt-4 d-flex align-items-center justify-content-start">
                  <div
                    class="info-text-background p-1 d-flex mx-2 text-center justify-content-center align-items-center"
                  >
                    <div class="box incerment-box mx-2"></div>
                    - % DEĞER ALTINDA
                  </div>

                  <div
                    class="info-text-background p-1 d-flex mx-2 text-center justify-content-center align-items-center"
                  >
                    <div class="box plus-box mx-2"></div>
                    + % DEĞER ÜSTÜNDE
                  </div>
                </div>
              </div>
            </template>
          </dashboard-box>
        </div>
      </div>

      <dashboard-box :title="$t('esc.esc_list')">
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="fields"
              :hover="false"
              :items="formattedReport"
              :no-border-collapse="true"
              :outlined="false"
              :page.sync="page"
              :perPage.sync="perPage"
              :striped="true"
              :infiniteId="infiniteId"
              tableVariant="''"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort" 
              :disabled-infinite-loading="false"
              :sticky-header="true"
            ></data-table> 
          </div>
        </template>
      </dashboard-box>      
    </div> 
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
// import SupplierSuccessReportBarChart from "./SupplierSuccessReportBarChart"; 

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER,
} from "@/core/services/store/report/supplierSuccessReport.module";

import { GET_ITEMS } from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_TIME_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  SUPPLIER_SUCCESS_REPORT_START_DATE_STORAGE_NAME,
  SUPPLIER_SUCCESS_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "SupplierSuccessReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    ContextMenu,
    // SupplierSuccessReportBarChart, 
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    formattedReport() {
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];
      let temp = [];
      let self = this;
      this.series=[];
      this.groupedData = [];
      this.chartCategories = [];
      this.items.data.forEach((item) => {
        temp.push({
          
        });
      }); 
      this.tempData = temp
      this.groupedRender()
      return temp;
    },
    chartOptions() {
      let length = 500;
      if (this.tmpSeries.length <= 15) {
        length = 750;
      } else {
        length = length + (this.tmpSeries.length - 7) * 30;
      }
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: 500, 
          width:900,
          stacked: true,
          stackType: "100%",
          responsive: [{
            breakpoint: 600,
            options: {
              chart: {
                width: '100%' // 600 piksel altında grafik genişliği yüzde 100
              }
            }
          }]
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Tedarikçi Termin Başarı Oranı",
          style: {
            fontSize: "13px",
            fontWeight: "bold",
            color: "#5cb7e0",
          },
        },
        xaxis: {
          categories: this.chartCategories,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        // legend: {
        //   position: "top",
        //   horizontalAlign: "left",
        //   offsetX: 40,
        //   onItemClick: {
        //     toggleDataSeries: false,
        //   },
        // },
      };
      return tmpChartOptions;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      sort: "desc",
      order: "id",
      userOptions: [],
      search: null,
      paginate: [],
      orderSizeAndColorExportDateBodySizes: [],
      page: 1,
      perPage: 25,
      currentStoreType: 99999999,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      download: false,
      reportListUrl: "api/report/supplier-success-report", 
      companies: [],
      orderList: [],
      tmpOrderData: [],
      tmpPackingListData: [],
      supplierCompanyOptions: [],
      moment: moment,
      onClickButton: false,
      fields: [  
        {
          key: "supplier_name",
          label: this.$t("Tedarikçi"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg ",
        },
        {
          key: "order_number",
          label: this.$t("Toplam Miktar"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "instructions_number",
          label: this.$t("İade Miktarı"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "customer_name",
          label: this.$t("İade Oranı(%)"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "show_order_detail",
          label: this.$t("#"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        }, 
      ], 
      team_ids:[],
      supplier_ids:[],
      order_ids:[],
      company_ids:[],
      teamList:[],
      companyList: [], 
      supplierList:[], 
      
      chartCategories: [], 
      series: [],
      tmpSeries: [],
      positive:[],
      negative:[],
      groupedData:[],
      tempData:[]
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER,
    }),  
    groupedRender(){  
      let self = this; 
      this.groupedData =[];
      this.series =[];
      this.positive = [];
      this.negative = [];  
      this.groupedData = this.tempData.reduce((acc, item) => {
          let supplier = acc.find(s => s.name === item.supplier_name);
          if (!supplier) {
              supplier = {
                  name: item.supplier_name,
                  data: [0, 0] 
              };
              acc.push(supplier);
          }

          if(item.planned_different >= 0 &&  item.real_different >= 0 ){
            supplier.data[0]++;
          }else{
            supplier.data[1]++;
          }

          return acc;
      }, []);
       
      this.groupedData.forEach((chartData, key) => {
        console.log(chartData)
        self.chartCategories.push(chartData.name); 
        self.positive.push(chartData.data[0]);
        self.negative.push(chartData.data[1]);
      });
       
      this.series = [
        {
          name: "Değerin Üstünde",
          data: this.positive,
          color: "#2BAA1E",
        },
        {
          name: "Değerin Altında",
          data: this.negative,
          color: "#B40407",
        },
      ]; 
    },  
    filterResult() {
      localStorage.setItem(
        SUPPLIER_SUCCESS_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        SUPPLIER_SUCCESS_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.series = []
      this.onHitBottom();
    },
    onContextMenu(event) {
      event.preventDefault();
    },
    onHitBottom($state = null) {
      if (this.loading) {
        return;
      } 
      let self = this;
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort); 
      if (this.team_ids){
        this.$set(filters, "team_ids", JSON.stringify(this.team_ids));
      }
      if (this.company_ids) {
        this.$set(filters, "company_ids", JSON.stringify(this.company_ids));
      }
      if (this.supplier_ids) {
        this.$set(filters, "supplier_ids", JSON.stringify(this.supplier_ids));
      }
      if (this.order_ids) {
        this.$set(filters, "order_ids", JSON.stringify(this.order_ids));
      } 
              
      this.series=[]; 
      this.groupedData = [];
      this.chartCategories = [];

      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            if ($state) {
              this.infiniteId++;
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });  
    },
    resetFilters() {
        (this.sort = "desc"),
        (this.order = "id"),
        this.team_ids=[],
        this.company_ids=[],
        this.supplier_ids=[],
        this.order_ids=[],
        this.setFilter({
          page: 1,
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });
    },
    setup() {
      let self = this,
      promises = []; 
      
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/team-list",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, { 
          url: "api/companies",
        })
      ); 
       
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing?",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/supplier-list/workmanship",
        })
      ); 

      Promise.all(promises).then((results) => {
        if (results[0].status) { 
          self.teamList = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "user_name"
          );
        }

        if (results[1].status) {
          self.companyList = self.convertArrayToObjectByKey(
            results[1].data,
            "id",
            "name"
          );
        }

        if (results[2].status) {
          this.orderList = results[2].data;
        }
       
        if (results[3].status) {
          self.supplierList = self.convertArrayToObjectByKey(
            results[3].data,
            "id",
            "name"
          );
        }

      });
    },
    orderFilter(param){
      let params=''; 
      if(param.team_ids){
        params += '&team_id='+JSON.stringify(param.team_ids);
      }
      if(param.company_ids){
        params += '&company_id='+JSON.stringify(param.company_ids);
      } 
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing?"+params, 
        })
        .then((result) => { 
          if (result.status) {
            this.orderList = result.data
          } else {
            console.log({ error: result });
          }
        });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {  
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("Tedarikçi Başarı Raporu") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      SUPPLIER_SUCCESS_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(SUPPLIER_SUCCESS_REPORT_END_DATE_STORAGE_NAME))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      SUPPLIER_SUCCESS_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(SUPPLIER_SUCCESS_REPORT_START_DATE_STORAGE_NAME))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();
    
    this.onClickButton = true;
    setTimeout(this.filterResult, 500);
  },
  watch: {
    sort() {
      this.filterResult();
    },
    order() {
      this.filterResult();
    },
    "team_ids"(value){ 
      this.orderFilter({'team_ids':value})
    },
    "company_ids"(value){ 
      this.orderFilter({'company_ids':value})
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
.info-text-background {
  background-color: #f2f2f7;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-weight: 500;
}
.box {
  width: 15px;
  height: 15px;
}
.incerment-box {
  background-color: #f63535;
}
.plus-box {
  background-color: #1ec00f;
}
.graphic-container {
  overflow-x: scroll;
  display: flex;
  position: relative;
}
#chart {
  width:100% !important;
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  background-color: white !important;
}
.b-table-sticky-header {
  max-height: 500px;
}
.b-table-sticky-header > .table.b-table > tbody > tr > th {
  background-color: white !important;
}

.b-table-sticky-header > .table.b-table > thead > tr > th:nth-child(2){
  border-right:3px solid  grey !important
}
</style>
